@font-face {
  font-family: 'Bahnschrift';
  font-stretch: semi-condensed;
  src: url('/public/bahnschrift.ttf');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.mdxeditor-popup-container {
  z-index: 1200 !important;
}

.MuiPopper-root-fixed {
  position: fixed !important;
}