/* CODE HLJS */


.hljs {
  padding: 4px 6px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.9rem;
  font-family: 'SF Mono', monospace;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.hljs > * {
  grid-column: 1;
  grid-row: 1;
}

/* MDX */
.mdxeditor {
  font-size: 1.1em;
  min-width: 0;
}

.mdxeditor a {
  color: var(--accentSolid);
}

.mdxeditor-popup-container {
  z-index: 99999999;
}

._nestedEditor_uazmk_963 {
  background: transparent !important;
}

.mdxeditor .mdxeditor-toolbar {
  flex-wrap: wrap;
  justify-content: space-between;
}

.mdxeditor .mdxeditor-toolbar > div:first-child {
  flex-wrap: wrap;
}

.mdxeditor .mdxeditor-toolbar > div:last-child {
  margin: 0 !important;
}

.mdxeditor h1 {
  font-size: 3em;
  line-height: 100%;
  margin: 0.6em 0;
}
.mdxeditor h2 {
  font-size: 2.5em;
  line-height: 100%;
  margin: 0.5em 0;
}
.mdxeditor h3 {
  font-size: 2em;
  line-height: 100%;
  margin: 0.4em 0;
}
.mdxeditor h4 {
  font-size: 1.75em;
  line-height: 100%;
  margin: 0.4em 0;
}
.mdxeditor h5 {
  font-size: 1.5em;
  line-height: 100%;
  margin: 0.4em 0;
}
.mdxeditor h6 {
  font-size: 1.25em;
  line-height: 100%;
  margin: 0.3em 0;
}

/** Code mirror */
.mdxeditor .cm-editor {
  --sp-font-mono: var(--font-mono);
  --sp-font-body: var(--font-body);
  padding: var(--sp-space-4) 0;
}

.mdxeditor .sp-editor .cm-editor {
  padding-bottom: 0;
}

.mdxeditor .cm-scroller {
  padding: 0 !important;
}

.mdxeditor .cm-focused {
  outline: none;
}

.mdxeditor .sp-wrapper {
  overflow: hidden;
}

.mdxeditor .sp-layout {
  border: none;
}

.mdxeditor .sp-cm pre {
  white-space: break-spaces;
  word-break: break-word;
  overflow-wrap: anywhere;
  flex-shrink: 1;
}

/** Diff viewer */

.mdxeditor .cm-mergeView .cm-scroller {
  font-family: var(--font-mono);
  line-height: 1.3rem;
  font-size: var(--text-xs);
}

/** Diff viewer */

.mdxeditor .cm-sourceView .cm-scroller {
  font-family: var(--font-mono);
  line-height: 1.3rem;
  font-size: var(--text-xs);
}

.mdxeditor .cm-gutters {
  background: transparent;
  font-size: var(--text-xxs);
}

.mdxeditor .cm-activeLine {
  background: transparent;
}

.mdxeditor .cm-tooltip-autocomplete {
  background: var(--baseBgSubtle);
}

.mdxeditor hr.selected[data-lexical-decorator=true] {
  outline: 2px solid highlight;
}

._editorRoot_uazmk_53 {
  --accentBase: var(--blue-1);
  --accentBgSubtle: var(--blue-2);
  --accentBg: var(--blue-3);
  --accentBgHover: var(--blue-4);
  --accentBgActive: var(--blue-5);
  --accentLine: var(--blue-6);
  --accentBorder: var(--blue-7);
  --accentBorderHover: var(--blue-8);
  --accentSolid: var(--blue-9);
  --accentSolidHover: var(--blue-10);
  --accentText: var(--blue-11);
  --accentTextContrast: var(--blue-12);

  --basePageBg: white;
  --baseBase: var(--slate-1);
  --baseBgSubtle: var(--slate-2);
  --baseBg: var(--slate-3);
  --baseBgHover: var(--slate-4);
  --baseBgActive: var(--slate-5);
  --baseLine: var(--slate-6);
  --baseBorder: var(--slate-7);
  --baseBorderHover: var(--slate-8);
  --baseSolid: var(--slate-9);
  --baseSolidHover: var(--slate-10);
  --baseText: var(--slate-11);
  --baseTextContrast: var(--slate-12);

  --admonitionTipBg: var(--cyan-4);
  --admonitionTipBorder: var(--cyan-8);
  --admonitionTipColor: var(--cyan-11);

  --admonitionInfoBg: var(--grass-4);
  --admonitionInfoBorder: var(--grass-8);
  --admonitionInfoColor: var(--grass-11);

  --admonitionCautionBg: var(--amber-4);
  --admonitionCautionBorder: var(--amber-8);
  --admonitionCautionColor: var(--amber-11);

  --admonitionDangerBg: var(--red-4);
  --admonitionDangerBorder: var(--red-8);
  --admonitionDangerColor: var(--red-11);

  --admonitionNoteBg: var(--slate-4);
  --admonitionNoteBorder: var(--slate-8);
  --admonitionNoteColor: var(--slate-11);

  --error-color: var(--red-10);

  --spacing-0: 0px;
  --spacing-px: 1px;
  --spacing-0_5: 0.125rem;
  --spacing-1: 0.25rem;
  --spacing-1_5: 0.375rem;
  --spacing-2: 0.5rem;
  --spacing-2_5: 0.625rem;
  --spacing-3: 0.75rem;
  --spacing-3_5: 0.875rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-14: 3.5rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-28: 7rem;
  --spacing-32: 8rem;
  --spacing-36: 9rem;
  --spacing-40: 10rem;
  --spacing-44: 11rem;
  --spacing-48: 12rem;
  --spacing-52: 13rem;
  --spacing-56: 14rem;
  --spacing-60: 15rem;
  --spacing-64: 16rem;
  --spacing-72: 18rem;
  --spacing-80: 20rem;
  --spacing-96: 24rem;

  --radius-none: 0px;
  --radius-small: var(--spacing-0_5);
  --radius-base: var(--spacing-1);
  --radius-medium: var(--spacing-1_5);
  --radius-large: var(--spacing-2);
  --radius-extra-large: var(--spacing-3);
  --radius-full: 9999px;

  /* --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
  /* --font-body: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

  --text-base: 1.1rem;
  --text-sm: 1rem;
  --text-xs: 1rem;
  --text-xxs: 0.9rem;

  font-family: var(--font-body);
  color: var(--baseText);
}

._editorWrapper_uazmk_154 {}

._nestedListItem_uazmk_156 {
  list-style: none;
}

._toolbarRoot_uazmk_160 {
  z-index: 2;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1);
  border-radius: var(--radius-medium);
  padding: var(--spacing-1_5);
  align-items: center;
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: var(--baseBg);
  width: inherit;
}

._toolbarRoot_uazmk_160 div[role=separator] {
  margin: var(--spacing-2) var(--spacing-1);
  border-left: 1px solid var(--baseBorder);
  border-right: 1px solid var(--baseBase);
  height: var(--spacing-4);
}

._toolbarRoot_uazmk_160 svg {
  color: var(--baseTextContrast);
  display: block;
}

._readOnlyToolbarRoot_uazmk_187 {
  pointer-events: none;
  background: var(--baseBase);
}

._readOnlyToolbarRoot_uazmk_187>div {
  opacity: 0.5;
}

._toolbarModeSwitch_uazmk_196 {
  opacity: 1 !important;
  margin-left: auto;
  align-self: stretch;
  align-items: stretch;
  display: flex;
  border: 1px solid var(--baseBg);
  border-radius: var(--radius-medium);
  font-size: var(--text-xs);
}

._toolbarModeSwitch_uazmk_196 ._toolbarToggleItem_uazmk_206 {
  padding-inline-end: var(--spacing-4);
  padding-inline-start: var(--spacing-4);
}

._toolbarModeSwitch_uazmk_196 ._toolbarToggleItem_uazmk_206:active,
._toolbarModeSwitch_uazmk_196 ._toolbarToggleItem_uazmk_206[data-state=on] {
  background-color: var(--baseBorder);
}

._toolbarGroupOfGroups_uazmk_217 {
  display: flex;
  margin: 0 var(--spacing-1);
}

._toolbarToggleSingleGroup_uazmk_222:first-of-type ._toolbarToggleItem_uazmk_206:only-child,
._toolbarToggleSingleGroup_uazmk_222:only-child ._toolbarToggleItem_uazmk_206:first-child,
._toolbarModeSwitch_uazmk_196 ._toolbarToggleItem_uazmk_206:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}

._toolbarToggleSingleGroup_uazmk_222:last-of-type ._toolbarToggleItem_uazmk_206:only-child,
._toolbarToggleSingleGroup_uazmk_222:only-child ._toolbarToggleItem_uazmk_206:last-child,
._toolbarModeSwitch_uazmk_196 ._toolbarToggleItem_uazmk_206:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarToggleItem_uazmk_206,
._toolbarButton_uazmk_237 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-0_5);
}

@media (hover: hover) {

  ._toolbarToggleItem_uazmk_206:hover,
  ._toolbarButton_uazmk_237:hover {
    background-color: var(--baseBgActive);
  }
}

._toolbarToggleItem_uazmk_206:active svg,
._toolbarButton_uazmk_237:active svg {
  transform: translate(1px, 1px);
}

._toolbarToggleItem_uazmk_206[data-state=on],
._toolbarButton_uazmk_237[data-state=on],
._toolbarToggleItem_uazmk_206:active,
._toolbarButton_uazmk_237:active {
  color: var(--baseTextContrast);
  background-color: var(--baseBgActive);
}

._toolbarToggleItem_uazmk_206[data-disabled],
._toolbarButton_uazmk_237[data-disabled] {
  pointer-events: none;
}

._toolbarToggleItem_uazmk_206[data-disabled] svg,
._toolbarButton_uazmk_237[data-disabled] svg {
  color: var(--baseBorderHover);
}

._toolbarButton_uazmk_237 {
  border-radius: var(--radius-base);
}

._toolbarButton_uazmk_237+._toolbarButton_uazmk_237 {
  margin-left: var(--spacing-1);
}

._activeToolbarButton_uazmk_274 {
  color: var(--accentText);
}

._toolbarToggleSingleGroup_uazmk_222 {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

._toolbarNodeKindSelectContainer_uazmk_284,
._toolbarButtonDropdownContainer_uazmk_285,
._toolbarCodeBlockLanguageSelectContent_uazmk_286,
._selectContainer_uazmk_287 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  z-index: 3;
  width: var(--spacing-36);
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
  background-color: var(--basePageBg);
  font-size: var(--text-sm);
}

._toolbarButtonDropdownContainer_uazmk_285 {
  border-top-right-radius: var(--radius-base);
}

._toolbarButtonDropdownContainer_uazmk_285 ._selectItem_uazmk_300:first-child {
  border-top-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectTrigger_uazmk_305,
._toolbarButtonSelectTrigger_uazmk_306,
._selectTrigger_uazmk_307 {
  border: 0;
  background-color: transparent;
  display: flex;
  color: inherit;
  align-items: center;
  width: var(--spacing-36);
  padding: var(--spacing-0_5) var(--spacing-1);
  padding-inline-start: var(--spacing-2);
  border-radius: var(--radius-medium);
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: var(--text-sm);
  background-color: var(--basePageBg);
  margin: 0 var(--spacing-1);
}

._toolbarNodeKindSelectTrigger_uazmk_305[data-state=open],
._toolbarButtonSelectTrigger_uazmk_306[data-state=open],
._selectTrigger_uazmk_307[data-state=open] {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  border-bottom-right-radius: var(--radius-none);
  border-bottom-left-radius: var(--radius-none);
}

._selectTrigger_uazmk_307[data-placeholder]>span:first-child {
  color: var(--baseBorderHover);
}

/** used in the sandpack */
._toolbarButtonSelectTrigger_uazmk_306 {
  width: auto;
  padding-inline-start: var(--spacing-2);
  padding-inline-end: var(--spacing-1);
  padding-block: var(--spacing-0_5);
}

._toolbarCodeBlockLanguageSelectTrigger_uazmk_342,
._toolbarCodeBlockLanguageSelectContent_uazmk_286 {
  width: var(--spacing-48);
}

._toolbarNodeKindSelectItem_uazmk_347,
._selectItem_uazmk_300 {
  cursor: default;
  display: flex;
  padding: var(--spacing-2);
}

._toolbarNodeKindSelectItem_uazmk_347[data-highlighted],
._selectItem_uazmk_300[data-highlighted] {
  background-color: var(--baseBg);
}

._toolbarNodeKindSelectItem_uazmk_347[data-state=checked],
._selectItem_uazmk_300[data-state=checked] {
  color: var(--baseTextContrast);
  background-color: var(--baseBg);
}

._toolbarNodeKindSelectItem_uazmk_347[data-highlighted],
._selectItem_uazmk_300[data-highlighted] {
  outline: none;
}

._toolbarNodeKindSelectItem_uazmk_347:last-child,
._selectItem_uazmk_300:last-child {
  border-bottom-left-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._toolbarNodeKindSelectDropdownArrow_uazmk_372,
._selectDropdownArrow_uazmk_373 {
  margin-left: auto;
  display: flex;
  align-items: center;
}

._contentEditable_uazmk_379 {
  box-sizing: border-box;
  width: 100%;
  color: var(--baseTextContrast);

  padding: var(--spacing-3);
}

._contentEditable_uazmk_379:focus {
  outline: none;
}

._codeMirrorWrapper_uazmk_391 {
  margin-bottom: var(--spacing-5);
  border: 1px solid var(--baseLine);
  border-radius: var(--radius-medium);
  overflow: hidden;
  padding: 0.8rem;
  position: relative;
}

._sandPackWrapper_uazmk_400 {
  margin-bottom: var(--spacing-5);
  border: 1px solid var(--baseLine);
  border-radius: var(--radius-medium);
  overflow: hidden;
  position: relative;
}

._codeMirrorToolbar_uazmk_408 {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: var(--spacing-1);
  padding: var(--spacing-1);
  z-index: 1;
  background-color: var(--baseBase);
  border-bottom-left-radius: var(--radius-base);
}

._frontmatterWrapper_uazmk_412 {
  border-radius: var(--radius-medium);
  padding: var(--spacing-3);
  background-color: var(--baseBgSubtle);
}

._frontmatterWrapper_uazmk_412[data-expanded=true] {
  margin-bottom: var(--spacing-10);
}

._frontmatterToggleButton_uazmk_422 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  font-size: var(--text-sm);
}

._propertyPanelTitle_uazmk_430 {
  font-size: var(--text-xs);
  font-weight: 400;
  margin: 0;
  padding-top: var(--spacing-2);
  padding-left: var(--spacing-2);
}

._propertyEditorTable_uazmk_438 {
  table-layout: fixed;
  border-spacing: var(--spacing-2);
}

._propertyEditorTable_uazmk_438 th {
  text-align: left;
  font-size: var(--text-sm);
  padding: var(--spacing-2) var(--spacing-3);
}

._propertyEditorTable_uazmk_438 col:nth-child(1) {
  width: 30%;
}

._propertyEditorTable_uazmk_438 col:nth-child(2) {
  width: 70%;
}

._propertyEditorTable_uazmk_438 td:last-child ._iconButton_uazmk_456 {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
}

._propertyEditorTable_uazmk_438 ._readOnlyColumnCell_uazmk_461 {
  padding-left: 0;
}

._propertyEditorLabelCell_uazmk_466 {
  font-weight: 400;
}

._readOnlyColumnCell_uazmk_461 {
  padding-left: 0;
}

._buttonsFooter_uazmk_474 {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2);
}

._propertyEditorInput_uazmk_480 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  font-size: var(--text-sm);
}

._iconButton_uazmk_456 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  color: var(--baseText);
}

@media (hover: hover) {
  ._iconButton_uazmk_456:hover {
    color: var(--baseTextContrast);
  }
}

._iconButton_uazmk_456:disabled,
._iconButton_uazmk_456:disabled:hover {
  color: var(--baseLine);
}

._primaryButton_uazmk_506,
._secondaryButton_uazmk_507 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-2) var(--spacing-3);
  border: 1px solid var(--accentBorder);
  background-color: var(--accentSolidHover);
  color: var(--baseBase);
  font-size: var(--text-xs);
  border-radius: var(--radius-medium);
}

._primaryButton_uazmk_506:disabled,
._secondaryButton_uazmk_507:disabled {
  background: var(--accentLine);
  border-color: var(--accentBg);
}

._smallButton_uazmk_522 {
  font-size: var(--text-xs);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-base);
}

._secondaryButton_uazmk_507 {
  border: 1px solid var(--baseBorder);
  background-color: var(--baseSolidHover);
  color: var(--baseBase);
}

._dialogForm_uazmk_534 {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
}

._linkDialogEditForm_uazmk_540 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-2);
  padding: 0;
}

._linkDialogInputContainer_uazmk_548 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._linkDialogInputWrapper_uazmk_554 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
}

._linkDialogInputWrapper_uazmk_554[data-visible-dropdown=true] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._linkDialogInputWrapper_uazmk_554>button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
}

._linkDialogInput_uazmk_548,
._dialogInput_uazmk_575 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._linkDialogInput_uazmk_548::-moz-placeholder,
._dialogInput_uazmk_575::-moz-placeholder {
  color: var(--baseBorder);
}

._linkDialogInput_uazmk_548::placeholder,
._dialogInput_uazmk_575::placeholder {
  color: var(--baseBorder);
}

._linkDialogAnchor_uazmk_586 {
  position: fixed;
  background-color: highlight;
  z-index: -1;
}

._linkDialogAnchor_uazmk_586[data-visible=true] {
  visibility: visible;
}

._linkDialogAnchor_uazmk_586[data-visible=false] {
  visibility: hidden;
}

._linkDialogPopoverContent_uazmk_600,
._tableColumnEditorPopoverContent_uazmk_601,
._dialogContent_uazmk_602 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  border: 1px solid var(--baseBg);
  background-color: var(--basePageBg);
  padding: var(--spacing-1) var(--spacing-1);
  font-size: var(--text-sm);
}

._largeDialogContent_uazmk_614 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-4);
  font-size: var(--text-sm);
}

._dialogTitle_uazmk_624 {
  font-size: var(--text-base);
  font-weight: 600;
  padding-left: var(--spacing-2);
}

._dialogCloseButton_uazmk_630 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  position: absolute;
  top: 10px;
  right: 10px;
}

._popoverContent_uazmk_637 {
  filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.20));
  display: flex;
  align-items: center;
  gap: var(--spacing-0_5);
  border-radius: var(--radius-medium);
  background-color: var(--baseBgSubtle);
  padding: var(--spacing-2) var(--spacing-2);
  font-size: var(--text-sm);
  z-index: 1;
}

._popoverArrow_uazmk_649 {
  fill: var(--basePageBg);
}

._linkDialogPreviewAnchor_uazmk_653 {
  margin-right: var(--spacing-1);
  display: flex;
  align-items: center;
  color: var(--accentText);
  text-decoration: none;

  border: 1px solid transparent;
}

@media (hover: hover) {
  ._linkDialogPreviewAnchor_uazmk_653:hover {
    color: var(--accentSolidHover);
  }
}

._linkDialogPreviewAnchor_uazmk_653 span {
  max-width: 14rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._tooltipTrigger_uazmk_676 {
  align-self: center;
}

._tooltipContent_uazmk_680 {
  z-index: 2;
  position: relative;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--text-xs);
  background-color: var(--baseText);
  color: var(--baseBase);
}

._tooltipContent_uazmk_680 svg {
  fill: var(--baseText);
}

._actionButton_uazmk_694 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  color: var(--baseTextContrast);
  padding: var(--spacing-1) var(--spacing-1);
  border-radius: var(--radius-medium);
  color: var(--baseTextContrast);
}

._actionButton_uazmk_694 svg {
  display: block;
}

@media (hover: hover) {
  ._actionButton_uazmk_694:hover {
    background-color: var(--baseBg);
  }
}

._actionButton_uazmk_694:active svg {
  transform: translate(1px, 1px);
}

._actionButton_uazmk_694[data-state=on],
._actionButton_uazmk_694:active {
  background-color: var(--baseBg);
  color: var(--baseTextContrast);
}

._primaryActionButton_uazmk_701 {
  background-color: var(--accentSolid);
  color: var(--baseBase);
}

@media (hover: hover) {
  ._primaryActionButton_uazmk_701:hover {
    background-color: var(--accentSolidHover);
    color: var(--baseBase);
  }
}

._tableEditor_uazmk_713 {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

._tableEditor_uazmk_713 thead>tr>th {
  text-align: right;
}

._tableEditor_uazmk_713>tbody>tr>td:not(._toolCell_uazmk_724),
._tableEditor_uazmk_713>tbody>tr>th:not(._toolCell_uazmk_724):not([data-tool-cell="true"]) {
  border: 1px solid var(--baseBgActive);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: normal;
}

:is(._tableEditor_uazmk_713>tbody>tr>td:not(._toolCell_uazmk_724), ._tableEditor_uazmk_713>tbody>tr>th:not(._toolCell_uazmk_724):not([data-tool-cell="true"]))>div {
  outline: none;
}

:is(._tableEditor_uazmk_713>tbody>tr>td:not(._toolCell_uazmk_724), ._tableEditor_uazmk_713>tbody>tr>th:not(._toolCell_uazmk_724):not([data-tool-cell="true"]))>div>p {
  margin: 0;
}

[data-active=true]:is(._tableEditor_uazmk_713>tbody>tr>td:not(._toolCell_uazmk_724), ._tableEditor_uazmk_713>tbody>tr>th:not(._toolCell_uazmk_724):not([data-tool-cell="true"])) {
  outline: solid 1px var(--baseSolid);
}

._tableEditor_uazmk_713 ._tableColumnEditorTrigger_uazmk_743,
._tableEditor_uazmk_713 ._tableRowEditorTrigger_uazmk_744,
._tableEditor_uazmk_713 ._addRowButton_uazmk_745,
._tableEditor_uazmk_713 ._addColumnButton_uazmk_746,
._tableEditor_uazmk_713 ._iconButton_uazmk_456 {
  opacity: .15;
}

@media (hover: hover) {

  ._tableEditor_uazmk_713:hover ._tableColumnEditorTrigger_uazmk_743,
  ._tableEditor_uazmk_713:hover ._tableRowEditorTrigger_uazmk_744,
  ._tableEditor_uazmk_713:hover ._addRowButton_uazmk_745,
  ._tableEditor_uazmk_713:hover ._addColumnButton_uazmk_746,
  ._tableEditor_uazmk_713:hover ._iconButton_uazmk_456 {
    opacity: 0.3;
  }

  ._tableEditor_uazmk_713:hover ._tableColumnEditorTrigger_uazmk_743:hover,
  ._tableEditor_uazmk_713:hover ._tableRowEditorTrigger_uazmk_744:hover,
  ._tableEditor_uazmk_713:hover ._addRowButton_uazmk_745:hover,
  ._tableEditor_uazmk_713:hover ._addColumnButton_uazmk_746:hover,
  ._tableEditor_uazmk_713:hover ._iconButton_uazmk_456:hover {
    opacity: 1;
  }
}

._toolCell_uazmk_724 {
  text-align: right;
}

._toolCell_uazmk_724 button {
  margin: auto;
  display: block;
}

._tableColumnEditorTrigger_uazmk_743 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  color: var(--baseTextContrast);
  padding: var(--spacing-1);
  border-radius: var(--radius-full);
  opacity: 0.2;
}

._tableColumnEditorTrigger_uazmk_743 svg {
  display: block;
}

@media (hover: hover) {
  ._tableColumnEditorTrigger_uazmk_743:hover {
    background-color: var(--baseBg);
  }
}

._tableColumnEditorTrigger_uazmk_743:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorTrigger_uazmk_743[data-state=on],
._tableColumnEditorTrigger_uazmk_743:active {
  background-color: var(--baseBg);
  color: var(--baseTextContrast);
}

._tableColumnEditorTrigger_uazmk_743[data-active=true] {
  opacity: 1 !important;
}

._tableColumnEditorToolbar_uazmk_789 {
  display: flex;
}

._tableColumnEditorToolbar_uazmk_789>button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  color: var(--baseTextContrast);
}

._tableColumnEditorToolbar_uazmk_789>button svg {
  display: block;
}

@media (hover: hover) {
  ._tableColumnEditorToolbar_uazmk_789>button:hover {
    background-color: var(--baseBg);
  }
}

._tableColumnEditorToolbar_uazmk_789>button:active svg {
  transform: translate(1px, 1px);
}

._tableColumnEditorToolbar_uazmk_789>button[data-state=on],
._tableColumnEditorToolbar_uazmk_789>button:active {
  background-color: var(--baseBg);
  color: var(--baseTextContrast);
}

._tableColumnEditorToolbar_uazmk_789 [role=separator] {
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
}


._toggleGroupRoot_uazmk_803 {
  display: inline-flex;
}


._toggleGroupRoot_uazmk_803 button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  color: var(--baseTextContrast);
}


._toggleGroupRoot_uazmk_803 button svg {
  display: block;
}


@media (hover: hover) {
  ._toggleGroupRoot_uazmk_803 button:hover {
    background-color: var(--baseBg);
  }
}


._toggleGroupRoot_uazmk_803 button:active svg {
  transform: translate(1px, 1px);
}


._toggleGroupRoot_uazmk_803 button[data-state=on],
._toggleGroupRoot_uazmk_803 button:active {
  background-color: var(--baseBg);
  color: var(--baseTextContrast);
}


._toggleGroupRoot_uazmk_803 button:first-child {
  border-top-left-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
}


._toggleGroupRoot_uazmk_803 button:last-child {
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

._tableToolsColumn_uazmk_821 {
  width: 2rem;
}

._tableToolsColumn_uazmk_821 button {
  margin: auto;
  display: block;
}

._leftAlignedCell_uazmk_830 {
  text-align: left;
}

._rightAlignedCell_uazmk_834 {
  text-align: right;
}

._centeredCell_uazmk_838 {
  text-align: center;
}

._addColumnButton_uazmk_746,
._addRowButton_uazmk_745 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding: var(--spacing-1);
  color: var(--baseTextContrast);
  background-color: var(--baseBase);

  display: flex;
  align-items: center;
}

._addColumnButton_uazmk_746 svg,
._addRowButton_uazmk_745 svg {
  display: block;
}

@media (hover: hover) {

  ._addColumnButton_uazmk_746:hover,
  ._addRowButton_uazmk_745:hover {
    background-color: var(--baseBg);
  }
}

._addColumnButton_uazmk_746:active svg,
._addRowButton_uazmk_745:active svg {
  transform: translate(1px, 1px);
}

._addColumnButton_uazmk_746[data-state=on],
._addRowButton_uazmk_745[data-state=on],
._addColumnButton_uazmk_746:active,
._addRowButton_uazmk_745:active {
  background-color: var(--baseBg);
  color: var(--baseTextContrast);
}

._addColumnButton_uazmk_746 svg,
._addRowButton_uazmk_745 svg {
  margin: auto;
}

._addRowButton_uazmk_745 {
  width: 100%;
  margin-top: var(--spacing-px);
  box-sizing: border-box;
  border-bottom-right-radius: var(--radius-medium);
  border-bottom-left-radius: var(--radius-medium);
}

._addColumnButton_uazmk_746 {
  margin-left: var(--spacing-px);
  height: 100%;
  border-top-right-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

/** Dialog */
._dialogOverlay_uazmk_871 {
  position: fixed;
  inset: 0;
  animation: _overlayShow_uazmk_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--baseBase);
  z-index: 51;
  opacity: 0.5;
}

._dialogContent_uazmk_602,
._largeDialogContent_uazmk_614 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: _contentShow_uazmk_1 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 52;
}

._dialogContent_uazmk_602:focus,
._largeDialogContent_uazmk_614:focus {
  outline: none;
}


@keyframes _overlayShow_uazmk_1 {
  from {
    opacity: 0;
  }

  to {
    opacity: .5;
  }
}

@keyframes _contentShow_uazmk_1 {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

._focusedImage_uazmk_918 {
  outline: highlight solid 2px;
}

._imageWrapper_uazmk_922 {
  display: inline-block;
  position: relative;
}

._imageWrapper_uazmk_922[draggable=true] {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}

._editImageToolbar_uazmk_935 {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: var(--spacing-1);
  padding: var(--spacing-1);
  z-index: 1;
  background-color: var(--baseBase);
  border-bottom-left-radius: var(--radius-base);
}

._editImageButton_uazmk_939 svg {
  display: block;
}

._inlineEditor_uazmk_945 {
  display: inline-flex;
  border-radius: var(--radius-medium);
  padding: var(--spacing-1);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_uazmk_954 {
  display: flex;
  justify-content: stretch;
  border-radius: var(--radius-medium);
  padding: var(--spacing-2);
  gap: var(--spacing-2);
  align-items: center;
  background: var(--baseBg);
}

._blockEditor_uazmk_954 ._nestedEditor_uazmk_963 {
  flex-grow: 1;
}

._nestedEditor_uazmk_963 {
  background: var(--basePageBg);
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-medium);
}

._nestedEditor_uazmk_963>p {
  margin: 0;
}

._nestedEditor_uazmk_963:focus {
  outline: none;
}

._genericComponentName_uazmk_982 {
  font-size: var(--text-sm);
  color: var(--baseText);
  padding-right: var(--spacing-2);
}

._diffSourceToggle_uazmk_988 {
  border-radius: var(--radius-medium);
  display: flex;
}

._diffSourceToggle_uazmk_988 ._toolbarToggleItem_uazmk_206 {
  padding: 0;
}

._diffSourceToggle_uazmk_988 ._toolbarToggleItem_uazmk_206>span {
  display: block;
  padding: var(--spacing-1) var(--spacing-1);
}

._selectWithLabel_uazmk_1002 {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  margin-left: var(--spacing-2);
}

._selectWithLabel_uazmk_1002>label {
  font-size: var(--text-sm);
}

._selectWithLabel_uazmk_1002 ._selectTrigger_uazmk_307 {
  border: 1px solid var(--baseBorder);
}

._toolbarTitleMode_uazmk_1017 {
  font-size: var(--text-sm);
  margin-left: var(--spacing-2);
}


._imageControlWrapperResizing_uazmk_1023 {
  touch-action: none;
}

._imageResizer_uazmk_1027 {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: var(--accentText);
  border: 1px solid var(--baseBg);
}

._imageResizer_uazmk_1027._imageResizerN_uazmk_1036 {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

._imageResizer_uazmk_1027._imageResizerNe_uazmk_1042 {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

._imageResizer_uazmk_1027._imageResizerE_uazmk_1048 {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

._imageResizer_uazmk_1027._imageResizerSe_uazmk_1054 {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

._imageResizer_uazmk_1027._imageResizerS_uazmk_1054 {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

._imageResizer_uazmk_1027._imageResizerSw_uazmk_1066 {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

._imageResizer_uazmk_1027._imageResizerW_uazmk_1072 {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

._imageResizer_uazmk_1027._imageResizerNw_uazmk_1078 {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

._placeholder_uazmk_1084 {
  color: var(--baseSolid);
  overflow: hidden;
  position: absolute;
  top: 0;
  padding: var(--spacing-3);
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

._rootContentEditableWrapper_uazmk_1097 {
  position: relative;
}



._downshiftContainer_uazmk_1103 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

._downshiftInputWrapper_uazmk_1109 {
  display: flex;
  align-items: center;
  background-color: var(--baseBase);

  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
}

._downshiftInputWrapper_uazmk_1109[data-visible-dropdown=true] {
  border-bottom-left-radius: var(--radius-none);
  border-bottom-right-radius: var(--radius-none);
  border-bottom-width: 0;
}

._downshiftInputWrapper_uazmk_1109>button {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  padding-right: var(--spacing-2);
}

._downshiftInput_uazmk_1109 {
  border: 0;
  background-color: transparent;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  all: unset;
  box-sizing: border-box;
  cursor: default;
  width: 20rem;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--text-sm);
}

._downshiftInput_uazmk_1109::-moz-placeholder {
  color: var(--baseBorder);
}

._downshiftInput_uazmk_1109::placeholder {
  color: var(--baseBorder);
}

._downshiftAutocompleteContainer_uazmk_1140 {
  position: relative;
}

._downshiftAutocompleteContainer_uazmk_1140 ul {
  all: unset;
  box-sizing: border-box;
  position: absolute;
  font-size: var(--text-sm);
  width: 100%;
  display: none;
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  max-height: var(--spacing-48);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--baseBorder);
  border-top-width: 0;
  background-color: var(--baseBase);
}

._downshiftAutocompleteContainer_uazmk_1140 ul[data-visible=true] {
  display: block;
}

._downshiftAutocompleteContainer_uazmk_1140 ul li {
  padding: var(--spacing-2) var(--spacing-3);
  white-space: nowrap;
  margin-bottom: var(--spacing-1);
  overflow-x: hidden;
  text-overflow: ellipsis;
}

._downshiftAutocompleteContainer_uazmk_1140 ul li[data-selected=true] {
  background-color: var(--baseBgSubtle);
}

._downshiftAutocompleteContainer_uazmk_1140 ul li[data-highlighted=true] {
  background-color: var(--baseBgHover);
}

._downshiftAutocompleteContainer_uazmk_1140 ul li:last-of-type {
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
}

._textInput_uazmk_1186 {
  all: unset;
  border-radius: var(--radius-base);
  border: 1px solid var(--baseBorder);
  background-color: var(--baseBase);
  padding: var(--spacing-2) var(--spacing-3);
}

form._multiFieldForm_uazmk_1194 {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2);
  gap: var(--spacing-2);
}

form._multiFieldForm_uazmk_1194 ._formField_uazmk_1200 {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

form._multiFieldForm_uazmk_1194 ._formField_uazmk_1200 label {
  font-size: var(--text-xs);
}

._markdownParseError_uazmk_1211 {
  border-radius: var(--radius-base);
  border: 1px solid var(--error-color);
  padding: var(--spacing-2);
  margin-block: var(--spacing-2);
  color: var(--error-color);
  font-size: var(--text-xs);
}

._popupContainer_uazmk_1220 {
  position: relative;
  z-index: 2;
}

._inputSizer_uazmk_1225 {
  display: inline-grid;
  vertical-align: baseline;
  align-items: center;
  position: relative;
}

._inputSizer_uazmk_1225::after,
._inputSizer_uazmk_1225 input {
  width: auto;
  min-width: 1rem;
  grid-area: 1 / 2;
  font: inherit;
  margin: 0;
  padding: 0 2px;
  resize: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  color: inherit;
}

._inputSizer_uazmk_1225 span {
  padding: 0.25em;
}

._inputSizer_uazmk_1225::after {
  content: attr(data-value);
  white-space: pre-wrap;
}


._bold_1tncs_10 {
  font-weight: bold;
}

._italic_1tncs_14 {
  font-style: italic;
}

._underline_1tncs_18 {
  text-decoration: underline;
}

._bold_1tncs_10 {
  font-weight: 700;
}

._italic_1tncs_14 {
  font-style: italic;
}

._underline_1tncs_18 {
  text-decoration: underline
}

._strikethrough_1tncs_34 {
  text-decoration: line-through
}

._underlineStrikethrough_1tncs_38 {
  text-decoration: underline line-through
}

._subscript_1tncs_42 {
  font-size: .8em;
  vertical-align: sub !important
}

._superscript_1tncs_47 {
  font-size: .8em;
  vertical-align: super
}

._code_1tncs_52 {
  background-color: var(--baseBg);
  padding: 1px .25rem;
  font-family: var(--font-mono);
  font-size: 94%
}

._nestedListItem_1tncs_59 {
  list-style: none;
  list-style-type: none;
}

._nestedListItem_1tncs_59:before,
._nestedListItem_1tncs_59:after {
  display: none;
}

._listitem_1tncs_69 {
  margin: var(--spacing-2) 0;
}

._listItemChecked_1tncs_73,
._listItemUnchecked_1tncs_74 {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-inline-start: -1rem;
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  list-style-type: none;
  outline: none;
}

._listItemChecked_1tncs_73 {
  text-decoration: line-through;
}

._listItemUnchecked_1tncs_74:before,
._listItemChecked_1tncs_73:before {
  content: '';
  width: var(--spacing-4);
  height: var(--spacing-4);
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}

._listItemUnchecked_1tncs_74[dir='rtl']:before,
._listItemChecked_1tncs_73[dir='rtl']:before {
  left: auto;
  right: 0;
}

._listItemUnchecked_1tncs_74:focus:before,
._listItemChecked_1tncs_73:focus:before {
  box-shadow: 0 0 0 2px var(--accentBgActive);
  border-radius: var(--radius-small);
}

._listItemUnchecked_1tncs_74:before {
  border: 1px solid var(--baseBorder);
  border-radius: var(--radius-small);
}

._listItemChecked_1tncs_73:before {
  border: 1px solid var(--accentBorder);
  border-radius: var(--radius-small);
  background-color: var(--accentSolid);
  background-repeat: no-repeat;
}

._listItemChecked_1tncs_73:after {
  content: '';
  cursor: pointer;
  border-color: var(--baseBase);
  border-style: solid;
  position: absolute;
  display: block;
  top: var(--spacing-0_5);
  width: var(--spacing-1);
  left: var(--spacing-1_5);
  right: var(--spacing-1_5);
  height: var(--spacing-2);
  transform: rotate(45deg);
  border-width: 0 var(--spacing-0_5) var(--spacing-0_5) 0;
}

._nestedListItem_1tncs_59 {
  list-style-type: none;
}

._nestedListItem_1tncs_59:before,
._nestedListItem_1tncs_59:after {
  display: none;
}

._admonitionDanger_1tncs_151,
._admonitionInfo_1tncs_152,
._admonitionNote_1tncs_153,
._admonitionTip_1tncs_154,
._admonitionCaution_1tncs_155 {
  padding: var(--spacing-2);
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  border-left: 3px solid var(--admonitionBorder);
  background-color: var(--admonitionBg);
  color: var(--admonitionColor);
}

._admonitionInfo_1tncs_152 {
  --admonitionBorder: var(--admonitionInfoBorder);
  --admonitionBg: var(--admonitionInfoBg);
  --admonitionColor: var(--admonitionInfoColor);
}

._admonitionTip_1tncs_154 {
  --admonitionBorder: var(--admonitionTipBorder);
  --admonitionBg: var(--admonitionTipBg);
  --admonitionColor: var(--admonitionTipColor);
}

._admonitionCaution_1tncs_155 {
  --admonitionBorder: var(--admonitionCautionBorder);
  --admonitionBg: var(--admonitionCautionBg);
  --admonitionColor: var(--admonitionCautionColor);
}

._admonitionDanger_1tncs_151 {
  --admonitionBorder: var(--admonitionDangerBorder);
  --admonitionBg: var(--admonitionDangerBg);
  --admonitionColor: var(--admonitionDangerColor);
}

._admonitionNote_1tncs_153 {
  --admonitionBorder: var(--admonitionNoteBorder);
  --admonitionBg: var(--admonitionNoteBg);
  --admonitionColor: var(--admonitionNoteColor);
}

._mdxExpression_1tncs_188 {
  font-family: var(--font-mono);
  font-size: 84%;
  color: var(--accentText);
}

._mdxExpression_1tncs_188 input:focus-visible {
  outline: none;
}